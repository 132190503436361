import PropTypes from 'prop-types';
import React from 'react';
import {Box, Heading} from '@chakra-ui/react';
import {Link} from 'gatsby';

export default function DefinitionListItem({link, title}) {
  return (
    <Link to={link}>
      <Box paddingY="2">
        <Heading as="h2" size="lg" color="green.600">
          {title}
        </Heading>
      </Box>
    </Link>
  );
}

DefinitionListItem.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
